body{
  margin: 0px;
  background-color: #000000;
}

header.header{
  position: absolute;
  z-index: 999999;
}

header.header img{
  max-width: 160px;
  margin: 30px;
}

header .logo{
  cursor: pointer;
}

h1{
  font-size: 2em;
  margin: 0px;
  color: #543b92;
}

h3{
  font-size: 1em;
  margin: 0px;
}

h3.marg-b{
  margin-bottom: 20px;
}

body h3 strong {
  font-size: 1em;
  margin-top: 10px;
  display: block;
  color: #ffffff;
}

p{
  font-size: 0.6em;
  margin: 0px;
}

p.error{
  margin-top: 10px;
}

.flexcontent{
  display: flex;
  justify-content: flex-start;
}

.flexcontentlog{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.flexbuttons{
  display: flex;
  flex-direction: row;
}

.flexcontent .formcontent{
  margin: 0 50px 20px 0;
  font-size: 20px;
}

.formcontent{
  margin: 0 50px 20px 0;
  font-size: 20px;
}

.App-header {
  position: relative;
  min-height: 100vh;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: #000000;
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Asegura que el contenido no se salga del contenedor */
}

.App-header::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("../public/images/fondo-banner.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: right;
  opacity: 0.1; 
  z-index: 1; 
}

.App-header > * {
  position: relative;
  z-index: 2; 
}

textarea {
  width: 80%;
  height: 150px;
  margin-top: 20px;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 2px solid #543b92; 
}

button {
  width: 200px;
  height: 50px;
  margin-top: 20px;
  margin-right: 20px;
  padding: 10px 20px;
  font-size: 18px;
  color: white;
  background-color: #543b92; 
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: 'SnowflakeSans'!important;
  font-weight: 400;
}

button:hover {
  border: solid 2px #543b92;
  background-color: #000000; 
  color: #543b92;
}

.conenedorpregunta{
  max-width: 900px;
}

.botonera_back_next{
  display: flex;
}

.botonera_back_next button{
  margin: 20px 10px 0 0;
}

.fade-enter {
  max-width: 900px;
  opacity: 0.01;
}

.fade-enter-done, .conenedorpregunta {
  display: flex;
  flex-direction: column;
  width: 900px;
  max-width: 900px;
  height: 80vh;
  justify-content: center;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

body h1{
  color: #543b92;
  font-family: 'SnowflakeInline';
  font-style: normal;
  font-size: 2em;
  line-height: 1em;
  margin-bottom: 25px;
}

h1.titulo_paso{
  color: #ffffff;
  font-family: 'SnowflakeInline';
  font-style: normal;
  font-size: 1em;
  line-height: 1em;
  margin-bottom: 10px;
  font-weight: 100;
}

body h3{
  color: #ffffff;
  font-family: 'SnowflakeSans';
  font-weight: 500;
  font-style: normal;
  font-size: 1em;
  line-height: 1.2em;
}

h3 span{
  color: #ffffff;
  font-family: 'SnowflakeSans';
  font-weight: 500;
  font-style: normal;
  font-size: 0.7em;
  line-height: 1.2em;
}

body strong{
  color: #543b92;
  font-family: 'SnowflakeSans';
  font-weight: 500;
  font-style: normal;
  font-size: 1em;
}

.swal2-modal strong{
  color: #5c5c5c;
}
  
body{
  color: #543b92;
  font-family: 'SnowflakeSans';
  font-weight: 300;
  font-style: normal;
  font-size: .6em;
}

.formcontent label{
  display: flex;
  flex-direction: column;
  width: 320px;
}

.formcontent label input{
  width: 300px;
  height: 40px;
  padding-bottom: 0px !important;
  border-radius: 5px;
  font-size: 18px;
  color: #543b92;
  border: none;
  margin: 5px 0 0 0;
  font-size: 16px;
  color: #543b92;
  padding: 5px 10px;
  font-family: 'SnowflakeSans';
  border: 2px solid #543b92; 
}

body textarea{
  color: #543b92;
  font-size: 16px;
  font-family: 'SnowflakeSans';
}

body textarea::placeholder{
  font-size: 16px;
  color: #543b92;
  font-family: 'SnowflakeSans';
}

.formcontent label.select {
  font-size: 18px;
  color: #543b92;
  font-family: 'SnowflakeSans'!important;
  position: relative; 
}

.formcontent label.select select{
  font-family: 'SnowflakeSans';
  width: 320px;
  height: 50px;
  padding-bottom: 0px !important;
  border-radius: 5px;
  border: none;
  margin: 5px 0 0 0;
  font-size: 16px;
  color: #543b92;
  padding: 0px 10px;
  -moz-appearance: none; 
  -webkit-appearance: none; 
  appearance: none;
  background: white; 
  border: 2px solid #543b92; 

}

.formcontent label.select::after{
  content: '▼';
  font-size: 16px;
  color: #543b92;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; 
}

.formcontent label input:focus-visible, .formcontent label select:focus-visible, textarea {
  outline: none;
}




/*--- User name form ---*/
p.usernameform{
  margin-bottom: 10px;
}



/*--- Escondemos text area paso 2 ---*/
.hidden-textarea {
  display: none;
}




/*--- Lista de empleados ---*/
#lista-empleados{
  margin: 0 auto;
  width: 60vw;
  height: 80vh;
  overflow: scroll;
  display: flex;
  align-items: center;
  flex-direction: column;
}

#lista-empleados ul{
  margin: 0;
  padding: 0;
}

#lista-empleados ul li{
  margin: 0px;
  list-style: none;
  padding: 20px;
  background-color: #543b92;
  cursor: pointer;
  margin-bottom: 20px;
}

#lista-empleados ul li:hover{
  background-color: #6c4dbb;
}


/*--- Loader ---*/
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 92%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999999;
}

.loader-content img {
  width: 200px;
  height: 200px;
  object-fit: contain;
  animation: bounce 1s infinite, fadeInOut 1s infinite;
}

@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

@keyframes fadeInOut {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}




/*--- Web Fonts ---*/
@font-face {
  font-family: 'SnowflakeInline';
  src: url('fonts/SnowflakeInline-Inlinex1.woff') format('woff'),
       url('fonts/SnowflakeInline-Inlinex2.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SnowflakeSans';
  src: url('fonts/SnowflakeSans-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SnowflakeSans';
  src: url('fonts/SnowflakeSans-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SnowflakeSans';
  src: url('fonts/SnowflakeSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'SnowflakeSans';
  src: url('fonts/SnowflakeSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SnowflakeSans';
  src: url('fonts/SnowflakeSans-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'SnowflakeSans';
  src: url('fonts/SnowflakeSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SnowflakeSans';
  src: url('fonts/SnowflakeSans-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'SnowflakeSans';
  src: url('fonts/SnowflakeSans-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'SnowflakeSans';
  src: url('fonts/SnowflakeSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SnowflakeSans';
  src: url('fonts/SnowflakeSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}